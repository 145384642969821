<!--
    @name: FLowEngine Index
    @description：FLowEngine Index
    @author: ZengWei
    @date: 2021-10-27 12:06
-->
<template>
	<div class="flow-container">
    <flow-designer
      ref="flowDesigner"
      v-if="!switchVisible && flowData"
      :form-uuid="formUuid"
      :flow-data="flowData"
      :user-data="userData"
      :form-item="formInputItem"
      :sub-flow-list="subFlowData"
      :editable="true"
      @on-save="submitFlow"
      @on-form="openFormDesign">
    </flow-designer>

    <div v-if="switchVisible" class="switch-show">
      <div>
        <img src="@/assets/img/flow.jpg" alt="">
        <p v-if="isForm">流程用于实现数据填写后的审批需求</p>
        <p v-if="isForm">如无需启用流程，可跳过此步骤</p>
        <p v-if="!isForm">暂未创建表单，请新增表单后使用流程！</p>
        <el-button v-else type="primary" size="small" class="button-class" @click="openFlow">开启流程</el-button>
      </div>
    </div>
    <div v-if="!switchVisible && flowData" class="close-button">
      <el-button type="primary" size="small" class="button-class" @click="closeFlow">关闭流程</el-button>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      title="流程节点自定义审批内容"
      fullscreen
      append-to-body
      custom-class="flow-form-design"
    >
      <section class="domain-body">
        <FormDesigner @form-data="saveFormDesign"></FormDesigner>
      </section>
    </el-dialog>
	</div>
</template>

<script>
  import {FlowDesigner} from "bimcc-ui"
	import {flowEngine} from '@/api/workflow'
  import objects from "@/api/objects";
  import {viewGetFormItem} from "@/custom-component/form/newParser/scripts/itemDataCopy";
  import {getDepartmentUser} from "@/api/saasManage";
  import FormDesigner from "@/custom-component/form/FormDesigner";

	export default {
		name: 'Index',
		components: {FlowDesigner,FormDesigner},
		props: {
      objectUuid: {
        type: String,
        default: ''
      },
      formUuid: {
        type: String,
        default: ''
      },
      needFlow: {
        type: String,
        default: ''
      }
		},
		data() {
			return {
				flowData: null,
        userData: null,
        formInputItem: null,
        subFlowData: [],
        switchVisible: true,
        isForm: true,
        dialogVisible: false,
			}
		},
		methods: {
      openFlow(){
        if(this.formUuid){
          objects.editFormFlow(this.formUuid,{need_flow:1}).then(res=>{
            if(res.data.code === 200){
              let param = {module: this.formUuid}
              this.flowDesign(param)
              this.switchVisible = false;
            }else{
              this.$message.error('开启流程失败');
            }
          })
        } else {
          this.$message.error('表单UUID为空不能创建流程');
        }
      },
      closeFlow(){
        if(this.formUuid){
          objects.editFormFlow(this.formUuid,{need_flow:0}).then(res=>{
            if(res.data.code === 200){
              this.switchVisible = true;
            }else{
              this.$message.error('关闭流程失败');
            }
          })
        } else {
          this.$message.error('表单UUID为空不能关闭流程');
        }
      },
			flowDesign(param) {
				flowEngine.designShow(param).then((res) => {
          objects.transFields(this.formUuid && this.formUuid !='null' ? this.formUuid : this.obuuid).then(async (resp) => {
            if (res.data.code == 200) {
              const formDesignData = resp.data.data;
              this.formInputItem = viewGetFormItem(formDesignData.fields);
              const userRes = await getDepartmentUser({})
              const subFlowRes = await flowEngine.designList()
              this.subFlowData = subFlowRes.data.data.data
              this.userData = userRes.data.data
              this.flowData = res.data.data
            }
          });
				})
			},
      submitFlow(data){
        flowEngine.designSave(this.flowData.id,data).then(res=>{
          if(res.data.code === 200) {
            this.$message.success('保存流程成功');
          }
        })
      },
      openFormDesign(){
        this.dialogVisible = true;
      },
      saveFormDesign(formDesignData){
        const design = JSON.parse(formDesignData.json)
        this.$refs.flowDesigner.setExtendForm(design)
        this.dialogVisible = false
      },
		},
		mounted() {
      if(this.formUuid && this.needFlow === 'true'){
        let param = {module: this.formUuid}
        this.flowDesign(param)
        this.switchVisible = false;
      }
		}
	}
</script>

<style lang="less" scoped>
:deep(.flow-form-design){
  .domain-body{
    width: 100%;
    height: calc(100vh - 60px);
  }
  .el-dialog__header{
    border-bottom: 1px solid #f1e8e8;
  }
  .el-dialog__body{
    padding: 0;
  }
}
	.flow-container {
    width: 100%;
		height: 100%;
    position: relative;
    .close-button {
      position: absolute;
      left: 20px;
      bottom: 20px;
    }
    .switch-show {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      p {
        color: #999;
        font-size: 16px;
        text-align: center;
        margin: 5px 0;
      }
      .button-class {
        margin-top: 10px;
      }
    }
	}
</style>
